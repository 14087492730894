<script setup lang="ts">
import * as yup from 'yup'
import { FormCheckboxField, FormField, FormDateField, FormListField, FormTextarea } from '#components'
// import { submitFormSubmission } from '~/clients/formSubmissions'

const props = defineProps<{
  token?: string
  form: Form & { formQuestions: FormQuestion[] }
  initialValues?: Record<string, any>
  size?: 'md' | 'lg'
  alignLeft?: boolean
}>()

// const { t: t } = useI18n({ useScope: 'global' })

const emit = defineEmits<{
  (e: 'submit', values: Record<string, any>): void
}>()

const { t } = useI18n({ useScope: 'local' })

function generateFormSchema(questions: FormQuestion[]) {
  if (!yup) throw new Error('Yup not found')

  if (!questions) {
    console.error('No questions found')
    return yup.object()
  }

  const schemaFields: Record<string, yup.AnySchema> = {}

  questions.forEach((question) => {
    let field: yup.AnySchema

    switch (question.questionType) {
      case QuestionType.Text:
        field = yup.string()
        if (!question.longAnswer) {
          // @ts-ignore
          field = field.max(255) // TODO: fix max
        }
        break

      case QuestionType.MultipleChoice:
        field = yup.string().oneOf(question.options || [])
        break

      case QuestionType.Date:
        field = yup.date()
        break

      case QuestionType.Number:
        field = yup.number()
        break

      case QuestionType.Checkbox:
        field = yup.boolean()
        break

      case QuestionType.Email:
        field = yup.string().email()
        break

      case QuestionType.Url:
        field = yup.string().url()
        break

      case QuestionType.Phone:
        field = yup.string().matches(
          /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
          'Invalid phone number',
        )
        break

      default:
        field = yup.string()
    }

    if (question.required) {
      field = field.required()
    }

    // Somehow this doesn't work well with id/numbers, so prepend with question_
    schemaFields[`question_${question.id}`] = field.label(question.text)
  })

  return yup.object().shape(schemaFields)
}

const schema = computed(() => generateFormSchema(props.form.formQuestions))

const { handleSubmit, isSubmitting, values } = useForm({
  validationSchema: toTypedSchema(schema.value),
  initialValues: props.initialValues ?? {},
})

const { handleServerError } = useServerErrorHandler()
const { handleValidationError } = useFormValidationErrorHandler()

const onSubmit = handleSubmit(async (values) => {
  try {
    const payload = {
      form_question_responses: Object.entries(values).map(([key, value]: [string, any]) => ({
        form_question_id: key.replace('question_', ''),
        value: value,
      })),
    }

    if (props.token) {
      await submitFormSubmission(props.token, payload)
    }
    else {
      await submitPublicForm(props.form.identifier, payload)
    }

    emit('submit', values)
  }
  catch (error) {
    console.error('Error submitting form', error)
    handleServerError(error)
  }
}, handleValidationError)

const getFieldComponent = (question: FormQuestion) => {
  switch (question.questionType) {
    case QuestionType.MultipleChoice:
      return FormListField
    case QuestionType.Checkbox:
      return FormCheckboxField
    case QuestionType.Text:
      return question.longAnswer ? FormTextarea : FormField
    case QuestionType.Date:
      return FormDateField
    case QuestionType.Email:
      return FormField // With email type
    case QuestionType.Phone:
      return FormField // With tel type
    case QuestionType.Url:
      return FormField // With url type
    default:
      return FormField
  }
}
</script>

<template>
  <FormCard
    :title="form.title"
    :sub-title="form.description"
    :is-submitting="isSubmitting"
    :show-cancel-button="false"
    :size="size"
    :align-left="alignLeft"
    @submit.prevent="onSubmit"
  >
    <template
      v-for="question in form.formQuestions"
      :key="question.id"
    >
      <component
        :is="getFieldComponent(question)"
        :name="`question_${question.id}`"
        :label="question.text"
        :type="question.questionType"
        :required="question.required"
        :items="question.options"
      />
    </template>
  </FormCard>
</template>

<i18n lang="json">
{
  "en": {
    "": ""
  },
  "nl": {
    "": ""
  }
}
</i18n>
